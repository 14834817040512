import React, { useContext, useEffect } from 'react';
import {
  HubHeader,
  Footer,
  EmailHandler,
  Intro,
  EmbracingJoy,
  EventCarousel,
  RenderWhenReady,
  SEO,
  PlayingForPatientImpact,
  AgendaAllDaysVisibleVersion
} from 'components';
import { LocalContext, FirebaseContext } from 'context';
import { scrollToAnchor } from 'utils';
import { useLocation } from '@reach/router';
import { pfizer } from 'styles';

const introText = `
  <h2>Our Theme</h2>
  <br />
  <p>Our Objective and our Why remains resolutely to <strong>change the trajectory of Cancer</strong> and improve the lives of  people living with cancer all over the world.
  <br /><br />
  Set against the backdrop of the pandemic, within the Pfizer team, we have witnessed up close how a small group of thoughtful committed individuals can indeed change the world, to paraphrase Margaret Mead, in fact it’s the only thing that ever has.
  <br /><br />
  We chose the pillars of <strong>Personal Growth, Business Growth and Patient Impact</strong> to underpin and guide the transformation that we are all experiencing at this moment.
  <br /><br />
  Beginning with each of us, we chose <strong>iTransform as a crystallization of this ambition</strong>.
  <br />
  </p>
`;

const Home = () => {
  const { setSelectedEvent } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);

  useEffect(() => {
    setSelectedEvent(null);
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     scrollToAnchor('info', {
  //       duration: 1200,
  //       offset: -20
  //     });
  //   }
  // }, [user]);

  return (
    <>
      <SEO pageSpecificTitle="Home" />
      <EmailHandler />
      <HubHeader colors={pfizer} />
      {user && <Intro text={introText} colors={pfizer} />}
      {user && <EmbracingJoy />}
      {user && <EventCarousel />}
      <RenderWhenReady>
        <AgendaAllDaysVisibleVersion />
      </RenderWhenReady>
      {/* {user && <PlayingForPatientImpact style={{ padding: '4.25rem 1rem 2.55rem' }} />} */}
      <Footer />
    </>
  );
};

export default Home;
